import React from 'react'
import background from "../../assets/home-background.jpg";
import {Container, Grid, Paper, Typography} from "@mui/material";
import {observer} from "mobx-react";
import BodyText from "../tiny/body-text";
import {styled} from "@mui/material/styles";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#FBB03B', // Adjust the color to match your image
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    width: 150,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '32px', // Adjust as necessary to match your image
    [theme.breakpoints.up('md')]: {
        // For sm breakpoint and up
        width: 200, // Larger width for sm breakpoint and up
        height: 200, // Larger height for sm breakpoint and up
    },

}));

const Pricing = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <section>
                <div className="anchor" id="Pricing"/>
                <Container maxWidth={false} disableGutters
                           sx={{
                               // minHeight: {xs: 300, md: 500},
                               // height: {xs: "50vh", md: "70vh"},
                               maxWidth: '90vw',
                               backgroundImage: `url(${background})`,
                               backgroundPosition: 'center',
                               backgroundRepeat:'no-repeat',
                               display: 'flex',
                           }}>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                        <BodyText>FylePro is a digital corporate document filing service. Our goal at FylePro is to provide
                            efficient service through our easy to use platform, and a transparent pricing model that
                            offers value to both small business owners and larger corporate structures alike.</BodyText>
                        <BodyText>Services we provide include:</BodyText>
                        <BodyText mt={0} pt={0} pb={1}>Corporate Annual Report Renewal Filings</BodyText>
                        <BodyText mt={0} pt={0} pb={1}>Corporate Fictitious Name (DBA) Registration</BodyText>
                        <BodyText mt={0} pt={0} pb={10}>Corporate Formations</BodyText>
                        {/*<BodyText>Included with all of our services is digital document storage center and automated reminders of upcoming filing due dates.</BodyText>*/}

                        <Container disableGutters maxWidth={false} sx={{display: 'flex', flexGrow: 0}}>
                            <Grid container spacing={2} justifyContent="space-around">
                                {[{'price':'119', 'text':`Corporate Annual Report\nRenewal Filings`},
                                    {'price':'99', 'text':`Corporate Formations`},
                                    {'price':'29', 'text':`Corporate Fictitious Name\n(DBA) Registration`}].map((item) => (
                                    <Grid item>
                                        <Item elevation={3}>
                                            <Typography variant="h3" sx={{ mt: 'auto', mb:'auto' }} component="div">
                                                ${item.price}
                                            </Typography>
                                            <Typography variant="body2"
                                             >
                                                + Local State Fees
                                            </Typography>

                                        </Item>
                                        <Typography variant="body1" textAlign='center' sx={{mt: 1}} display="block" gutterBottom>
                                            {item.text.split('\n').map((line, lineIndex) => (
                                                <React.Fragment key={lineIndex}>
                                                    {line}
                                                    {lineIndex !== item.text.split('\n').length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                    </Grid>
                                ))}




                                {/* Repeat <Grid item>...</Grid item> for the other two cards */}
                            </Grid>

                        </Container>
                    </Container>


                </Container>
            </section>

        </>
    )
}

export default observer(Pricing);
