import React from 'react'
import background from "../../assets/home-background.jpg";
import {Container, Grid, Paper, Typography} from "@mui/material";
import {observer} from "mobx-react";
import BodyText from "../tiny/body-text";
import {styled} from "@mui/material/styles";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: '#FBB03B', // Adjust the color to match your image
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    width: 150,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '32px', // Adjust as necessary to match your image
    [theme.breakpoints.up('md')]: {
        // For sm breakpoint and up
        width: 200, // Larger width for sm breakpoint and up
        height: 200, // Larger height for sm breakpoint and up
    },

}));

const TypesOfCorps = () => {

    return (
        <>
            <section>
                <div className="anchor" id="Types of Corps"/>
                <Container maxWidth={false} disableGutters
                           sx={{
                               // minHeight: {xs: 300, md: 500},
                               // height: {xs: "50vh", md: "70vh"},
                               maxWidth: '90vw',
                               backgroundImage: `url(${background})`,
                               backgroundPosition: 'center',
                               backgroundRepeat: 'no-repeat',
                               display: 'flex',
                           }}>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                        <BodyText>DISCLAIMER: FylePo does not provide tax, legal or accounting advice. This material has
                            been prepared for informational purposes only, and is not intended to provide, and should
                            not be relied on for, tax, legal or accounting advice. You should consult your own tax,
                            legal and accounting advisors before engaging in any transaction.</BodyText>

                        <Container disableGutters maxWidth={false} sx={{display: 'flex', flexGrow: 0}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5" textAlign='center' sx={{mt: 1}} display="block"
                                                gutterBottom>
                                        LIMITED LIABILITY COMPANY (LLC)
                                    </Typography>
                                    <BodyText>An LLC, or Limited Liability Company, is a business structure that
                                        combines the pass-through taxation of a partnership or sole proprietorship with
                                        the limited liability of a corporation. This means that the owners (called
                                        members) are not personally liable for the company's debts or liabilities beyond
                                        the amount of their investment in the company. LLCs are a popular choice for
                                        small businesses and startups due to their flexibility, simplicity, and
                                        liability protection.</BodyText>
                                    <Typography variant="h5" textAlign='center' sx={{mt: 1}} display="block"
                                                gutterBottom>
                                        S CORPORATION
                                    </Typography>
                                    <BodyText>An S Corporation (S-Corp) is a special type of corporation that meets
                                        specific IRS requirements to avoid double taxation. Like a regular corporation
                                        (C-Corp), an S-Corp is a separate legal entity, providing limited liability
                                        protection to its owners (shareholders). However, unlike a C-Corp, an S-Corp
                                        passes its income, losses, deductions, and credits through to its shareholders
                                        for federal tax purposes. This means that the S-Corp itself is not taxed on its
                                        income; instead, shareholders report the income on their individual tax returns.
                                        S-Corps are popular for small to mid-sized businesses due to their tax
                                        advantages and flexibility in ownership.</BodyText>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5" textAlign='center' sx={{mt: 1}} display="block"
                                                gutterBottom>
                                        C CORPORATION
                                    </Typography>
                                    <BodyText>
                                        A C Corporation (C-Corp) is a legal structure for a business that is separate
                                        from its owners (shareholders). Unlike a sole proprietorship or partnership,
                                        where the owners are personally liable for the business's debts and liabilities,
                                        a C-Corp provides limited liability protection to its shareholders. This means
                                        that the shareholders are generally not personally responsible for the debts and
                                        obligations of the corporation.
                                        <br/>
                                        One of the key features of a C-Corp is that it is taxed as a separate entity
                                        from its owners. This means that the corporation itself pays taxes on its
                                        income, and the shareholders also pay taxes on any dividends or distributions
                                        they receive from the corporation. This can result in what is often referred to
                                        as "double taxation," where the same income is taxed at both the corporate level
                                        and the individual level.
                                        <br/>
                                        Despite the potential for double taxation, C-Corps offer several advantages,
                                        including the ability to raise capital through the sale of stock, flexibility in
                                        ownership and management structure, and potential tax benefits, such as the
                                        ability to deduct certain employee benefits and expenses. C-Corps are often used
                                        by larger companies and those planning to go public or seek outside investment.
                                    </BodyText>
                                </Grid>
                            </Grid>


                        </Container>
                    </Container>


                </Container>
            </section>

        </>
    )
}

export default observer(TypesOfCorps);
