import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import Public from "./Public";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Unsubscribe from "./Unsubscribe";
import Renew from "./Renew";
import Complete from "./Complete";
import ReceiptWrapper from "./ReceiptWrapper";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://24e775291959afb354c15bb4f6af9219@o4506724127997952.ingest.sentry.io/4506724157292544",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


const router = createBrowserRouter([
    {
        path: "/",
        element: <Public />,
    },
    {
        path: "/renew",
        element: <Renew />,
    },
    // {
    //     path: "/already-renewed",
    //     element: <App />,
    // },
    {
        path: "/complete",
        element: <Complete />,
    },
    {
        path: "/receipt",
        element: <ReceiptWrapper />,
    },
    // {
    //     path: "/dashboard",
    //     element: <Dashboard />,
    // },
    // {
    //     path:'/activate',
    //     element:<Activate/>,
    // },
    {
        path:'/terms-and-conditions',
        element:<TermsAndConditions/>,
    },
    {
        path:'/privacy',
        element:<PrivacyPolicy/>,
    },
    // {
    //     path:'/reset-password',
    //     element:<PasswordReset/>,
    // },
    // {
    //     path:'/login',
    //     element:<LoginPage/>,
    // },
    {
        path:'/unsubscribe',
        element:<Unsubscribe/>,
    }
]);

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);