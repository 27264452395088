import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {main} from "../../assets/colors";
import CustomizedAccordions from "../faq-accordion";
import {Button, ButtonProps, Container} from "@mui/material";
import Spacer from "../Spacer";
import {styled} from "@mui/material/styles";
import BodyText from "../tiny/body-text";

const Contact = () => {

    const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
        color: main,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: main[700],
        },
    }));

    function mailTo(e) {
        window.location.href = "mailto:helpdesk@fylepro.com";
        e.preventDefault();
    }

    return (
        <>
            <section>
                <div className="anchor" id="Contact Us"/>

                <Container maxWidth={false} disableGutters
                           sx={{
                               position: 'relative',
                               py: 5,
                               px: {xs: 0, sm: 5, md: 10},
                               background: '#FBB03B45',
                               minHeight: '60vh',
                               // display: 'flex',
                               // alignItems: 'center',
                               // justifyContent: 'center',
                               // flexDirection: 'column',
                           }}>
                    <Container>
                        <Typography variant="h5" textAlign='center' sx={{mt: 1}} display="block"
                                    gutterBottom>
                            CONTACT US
                        </Typography>
                        <BodyText>Question about a recent order? Having trouble accessing your files? Or have a general
                            question before you file? Please feel free to reach out to us!
                        </BodyText>
                        <BodyText>We look forward to connecting with you!</BodyText>
                        <BodyText pb={0}>Refunds & Cancellations</BodyText>
                        <BodyText pt={0} mt={0}>Orders can be cancelled at any time through your receipt. Once a document has been filed
                            only the service fee can be refunded as local state filing fees are non-refundable.
                        </BodyText>
                        <BodyText>You can reach us directly via email at <a href={'mailto:helpdesk@fylepro.com'}>helpdesk@fylepro.com</a>.</BodyText>

                    </Container>



                </Container>
            </section>

        </>
    )
}

export default Contact;
