import React from 'react'
import './App.css'
import {observer} from "mobx-react";
import Contact from "./components/home/Contact";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import Pricing from "./components/home/Pricing";
import WhyIncorporate from "./components/home/WhyIncorporate";
import TypesOfCorps from "./components/home/TypesOfCorps";

const Public = () => {

    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={false}/>
            <main>
                <Pricing />
                <WhyIncorporate />
                <TypesOfCorps />
                <Contact />
                <Footer />
            </main>

        </>
    )
}

export default observer(Public);
