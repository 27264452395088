import React from 'react'
import background from "../../assets/home-background.jpg";
import {Container, Grid, Paper, Typography} from "@mui/material";
import {observer} from "mobx-react";
import BodyText from "../tiny/body-text";
import {styled} from "@mui/material/styles";

const StyledContainer = styled(Container)(({theme}) => ({
    // ... existing styles ...
    position: 'relative', // Make sure your container is relative
    overflow:'hidden',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: 0,
        height: 0,
        left: '-101vw',
        border: '100vw solid transparent',
        borderTop: '150px solid #FEEACA',
        borderBottom: 0,
        zIndex: -10,
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        width: 0,
        height: 0,
        left: '',
        border: '100vw solid transparent',
        borderTop:0,
        borderBottom:  '150px solid #FEEACA',
        zIndex: -10,
    },
}));

const WhyIncorporate = () => {
    return (
        <>
            <section>
                <div className="anchor" id="Why Incorporate?"/>
                <StyledContainer disableGutters maxWidth={false}>
                    <Container maxWidth={false} disableGutters
                               sx={{
                                   // minHeight: {xs: 300, md: 500},
                                   // height: {xs: "50vh", md: "70vh"},
                                   maxWidth: '90vw',
                                   backgroundImage: `url(${background})`,
                                   backgroundPosition: 'center',
                                   backgroundRepeat: 'no-repeat',
                                   display: 'flex',
                               }}>
                        <Container disableGutters
                                   sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                            <Typography sx={{
                                textAlign: "left",
                                fontSize: {xs: "1.6rem", md: "1.9rem"},
                                p: 3,
                                mt:{xs: 4, sm: 2,},
                                lineHeight: {xs: 1.5, md: 1},
                                fontWeight: "medium"
                            }}>Understanding when’s the right time to file a corporation.</Typography>

                            <BodyText pb={0}>DISCLAIMER: FylePro does not provide tax, legal or accounting advice. This
                                material
                                has been prepared for informational purposes only, and is not intended to provide, and
                                should not be relied on for, tax, legal or accounting advice. You should consult your
                                own
                                tax, legal and accounting advisors before engaging in any transaction.
                            </BodyText>

                            <BodyText pb={0}>You should consider filing for a corporation, such as a C-Corp or an
                                S-Corp, when you
                                want to establish a separate legal entity for your business that provides limited
                                liability
                                protection to its owners (shareholders). Here are some common scenarios when filing for
                                a
                                corporation might be appropriate:
                            </BodyText>
                            <BodyText pb={0}>Limited Liability Protection: If you want to protect your personal assets
                                from
                                business debts and liabilities, forming a corporation can provide limited liability
                                protection to its owners.
                            </BodyText>
                            <BodyText pt={0} pb={0}>Tax Flexibility: Depending on your business goals and tax situation,
                                a C-Corp or an
                                S-Corp structure may offer tax advantages, such as the ability to deduct certain
                                expenses or
                                to pass through income to shareholders without double taxation.
                            </BodyText>
                            <BodyText pt={0} pb={0}>Raising Capital: If you plan to raise capital by selling stock or
                                attracting
                                investors, a corporation may be a more attractive option than other business structures.
                            </BodyText>
                            <BodyText pt={0} pb={0}>Perpetual Existence: A corporation can exist indefinitely, even if
                                ownership changes,
                                making it a stable structure for long-term business operations.
                            </BodyText>
                            <BodyText pt={0} pb={0}>Professional Image: Operating as a corporation can convey a sense of
                                professionalism
                                and credibility to customers, suppliers, and potential partners.
                            </BodyText>
                            <BodyText pt={0} pb={10}>Before deciding to file for a corporation, it's advisable to
                                consult with a legal or
                                tax advisor to understand the specific implications for your business and to ensure that
                                you
                                comply with all legal and regulatory requirements.
                            </BodyText>

                            {/*<BodyText>Included with all of our services is digital document storage center and automated reminders of upcoming filing due dates.</BodyText>*/}

                        </Container>


                    </Container>
                </StyledContainer>

            </section>

        </>
    )
}

export default observer(WhyIncorporate);
