import React, {useEffect, useState} from 'react'
import {Avatar, Container, Divider, Link, Typography} from "@mui/material";
import logoChipGrey from "../../assets/logo-chip-grey.png";
import {background} from "../../assets/colors";

const Footer = () => {

    return (
        <>
            <section>
                <Divider/>
                <Container sx={{py: 0, px: {xs: 0, md: 10}, pb:2}}>

                    <Container sx={{display:'flex', alignItems:'end', justifyContent:'end'}}>
                        <Link href={"https://www.fylepro.com/terms-and-conditions"}>Terms and Conditions</Link>
                        <Container disableGutters sx={{mx:2, width:'fit-content'}}>|</Container>
                        <Link href={"https://www.fylepro.com/privacy"}>Privacy Policy</Link>
                    </Container>
                </Container>

            </section>

        </>
    )
}

export default Footer;
