import React from "react";
import ResponsiveAppBar from "./responsive-app-bar";
import {Container, Typography} from "@mui/material";
import Spacer from "./Spacer";
import Footer from "./home/Footer";

function NoFurtherRenewals(){
    return (<>
        <ResponsiveAppBar showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Typography align={"center"} variant={"h4"}>Thank you for choosing FylePro for your filing needs.</Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                The period for our 2024 renewal service has ended. If you would like additional information on filing your annual report please refer to the Florida Division of Corporations.
            </Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                You can always reach out to us at <a href={"mailto:helpdesk@fylepro.com"}>helpdesk@fylepro.com</a> with any additional questions.
            </Typography>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);
}

export default NoFurtherRenewals