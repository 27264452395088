import {styled} from "@mui/material/styles";
import {Button, ButtonProps} from "@mui/material";
import {main, shadow} from "../assets/colors";

export const DarkButton = styled(Button)<ButtonProps>(({theme}) => ({
    borderRadius:60,
    color: 'white',
    backgroundColor: main,
    '&:hover': {
        backgroundColor: shadow,
        color:main,
    },
}));

export const CompactDarkButton = styled(Button)<ButtonProps>(({theme}) => ({
    borderRadius:60,
    marginLeft:15,
    color: 'white',
    backgroundColor: main,
    padding:0,
    '&:hover': {
        backgroundColor: shadow,
        color:main,
    },
}));

export const LightButton = styled(Button)<ButtonProps>(({theme}) => ({
    borderRadius:60,
    color: main,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: main[700],
    },
}));

export const InlineButton = styled(Button)<ButtonProps>(({theme}) => ({
    outline:'none',
    borderRadius:0,
    color: main,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: main[700],
    },
    lineHeight:0,
    padding:0,
    minWidth:'0',
    fontSize:'0.875em',
    marginLeft:'20px',
}));