import './App.css'
import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import {Container, LinearProgress, Typography} from "@mui/material";
import {determineLocation} from "./assets/determineLocation";
import Spacer from "./components/Spacer";

const Unsubscribe = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("d");

    const [tokenFailure, setTokenFailure] = useState(false);

    React.useEffect(() => {
        if (token != null) {
            console.log("what");
            fetch(`${determineLocation()}/user/unsubscribe/${token}`, {method: 'GET'})
                .then(x => {
                    setTokenFailure(true);
                });
        } else {
            navigate(`/`)
        }

    }, []);

    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={false}/>
            <main>
                <Container sx={{height: "100vh"}}>
                    <Typography align={"center"} sx={{marginTop: 5}}>
                        {tokenFailure ? <>
                                We are sorry to see you go! <br/> You have been removed from our mailing list.</> :
                            <>
                                <Typography>Checking...</Typography>
                                <Spacer height={10}/>
                                <LinearProgress/>
                            </>}
                    </Typography>
                </Container>
                <Footer/>
            </main>

        </>
    )
}

export default Unsubscribe;
